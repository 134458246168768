import React from "react"
import { H1, H3, options, Pt, P } from "../../components/theme"
import styled from "styled-components"

const Container = styled.div`
  margin: 80px 160px;
`

const Content = styled.div`
  margin-top: 50px;
  h1 {
    text-align: center;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Card = styled.div`
  background: #191919;
  margin: 20px;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`

const Headline = styled.div`
  p {
    text-align: center;
    margin: 30px 0 !important;
    font-weight: bold;
    font-size: 20px !important;
  }
`

const Text = styled.div`
  height: 250px;
  p {
    font-size: 16px;
    margin: 30px 0 0 15px;
  }
`

const Price = styled.div`
  background: ${options.turquoise};
  height: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    font-size: 26px;
    margin: 0;
  }
`

const PriceSection = () => {
  return (
    <Container>
      <H1>CENE USLUGA</H1>
      <Content>
        <H3>USKORO !!!</H3>
        {/* <Flex>
          <Card>
            <Text>
              <Headline>
                <Pt>1 trening</Pt>
              </Headline>
              <P>- Miha Bodytec</P>
              <P>- Regularne Sprave</P>
            </Text>
            <Price>
              <P>2.400 rsd</P>
            </Price>
          </Card>
          <Card>
            <Text>
              <Headline>
                <Pt>4 treninga mesečno</Pt>
              </Headline>
              <P>- Miha Bodytec</P>
              <P>- Regularne Sprave</P>
            </Text>
            <Price>
              <P>8.400 rsd</P>
            </Price>
          </Card>
          <Card>
            <Text>
              <Headline>
                <Pt>8 treninga mesečno</Pt>
              </Headline>
              <P>- Miha Bodytec</P>
              <P>- Regularne Sprave</P>
              <P>- korišćenje saune je uključeno u cenu</P>
            </Text>
            <Price>
              <P>14.400 rsd</P>
            </Price>
          </Card>
          <Card>
            <Text>
              <Headline>
                <Pt>12 treninga mesečno</Pt>
              </Headline>
              <P>- Miha Bodytec</P>
              <P>- Regularne Sprave</P>
              <P>- korišćenje saune je uključeno u cenu</P>
            </Text>
            <Price>
              <P>18.000 rsd</P>
            </Price>
          </Card>
        </Flex>
      </Content>
      <Content>
        <H3>CIRKULARNI TRENINZI</H3>
        <FlexCenter>
          <Card>
            <Text>
              <Headline>
                <Pt>Mesečno</Pt>
              </Headline>
              <P>- CIRKULARNI TRENINZI</P>
              <P>- MAX 12 LJUDI U GRUPI</P>
              <P>- Doplata za saunu: 1.200 rsd/mo</P>
            </Text>
            <Price>
              <P>5.500 rsd</P>
            </Price>
          </Card>
        </FlexCenter>
      </Content>
      <Content>
        <H3>SAMOSTALNO VEŽBANJE</H3>
        <FlexCenter>
          <Card>
            <Text>
              <Headline>
                <Pt>1 termin</Pt>
              </Headline>
              <P>- Regularne Sprave</P>
              <P>- Doplata za saunu</P>
            </Text>
            <Price>
              <P>1.200 rsd</P>
            </Price>
          </Card>

          <Card>
            <Text>
              <Headline>
                <Pt>Mesečno</Pt>
              </Headline>
              <P>- Regularne Sprave</P>
              <P>- Doplata za saunu</P>
            </Text>
            <Price>
              <P>7.200 rsd</P>
            </Price>
          </Card>
        </FlexCenter>
      </Content>
      <Content>
        <H3>SAUNA</H3>
        <FlexCenter>
          <Card>
            <Text>
              <Headline>
                <Pt>1 termin </Pt>
              </Headline>
              <P>- za eksternog gosta</P>
            </Text>
            <Price>
              <P>1.200 rsd</P>
            </Price>
          </Card>
          <Card>
            <Text>
              <Headline>
                <Pt>1 termin</Pt>
              </Headline>
              <P>- za korisnike naših paketa treninga</P>
            </Text>
            <Price>
              <P>400 rsd</P>
            </Price>
          </Card>
        </FlexCenter> */}
      </Content>
    </Container>
  )
}

export default PriceSection
