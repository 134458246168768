import React, { Component } from "react"
import { H1, H3, options, Pt, P } from "../../components/theme"
import styled from "styled-components"
import "../price.css"
import GridComponent from "../../components/GridComponent"
import Weights from "../../assets/dumbbell.svg"
import Bolt from "../../assets/thunderbolt.svg"
import Manicure from "../../assets/manicure.svg"
import HairDryer from "../../assets/hair-dryer.svg"
import Massage from "../../assets/massage.svg"
import Spa from "../../assets/spa.svg"
import Pedicure from "../../assets/pedicure.svg"
import { Link } from "gatsby"

const Container = styled.div`
  padding: 80px 160px;
  position: relative;
  /* z-index: 1; */
  @media screen and (max-width: 1350px) {
    padding: 80px;
  }
  @media screen and (max-width: 900px) {
    padding: 40px;
  }
  @media screen and (max-width: 770px) {
    padding: 20px;
  }
  @media screen and (max-width: 760px) {
    padding: 20px 0;
    background: #191919;
  }
`

const Content = styled.div`
  z-index: 2;
  h1 {
    margin: 0 0 50px 0;
    font-size: 50px !important;
  }
  @media screen and (max-width: 900px) {
    /* padding: 40px; */
    h1 {
      text-align: center;
    }
  }
  @media screen and (max-width: 550px) {
    /* padding: 40px; */
    h1 {
      font-size: 30px !important;
      padding: 0px 10px;
    }
  }
`

const Flex = styled.div`
  z-index: 3;
  @media screen and (max-width: 680px) {
    /* display: none; */
  }
`

const Icons = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  align-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  width: 100%;
`

const Item = styled.div`
  /* z-index: 6; */
  width: 200px;
  /* flex-basis: 30%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background: purple; */
  margin: 20px;
  svg {
    width: 80px;
    path {
      fill: white;
    }
  }
  p {
    padding: 0 10px;
    margin: 20px 0;
    text-align: center;
    font-weight: bold;
    font-size: 15px !important;
    white-space: wrap;
  }
  @media screen and (max-width: 760px) {
    width: 50%;
    margin: 0;
  }
`

const Card = styled.div`
  z-index: 4;
  /* background: #191919; */
  width: 100%;
  /* border-radius: 10px; */
  /* padding: 50px; */
  @media screen and (max-width: 725px) {
  }
  @media screen and (max-width: 680px) {
  }
  @media screen and (max-width: 680px) {
  }
`

const Text = styled.div`
  z-index: 4;
  position: relative;
  p {
    /* z-index: 6; */
    font-size: 16px;
    margin: 30px 0 0 15px;
  }
  @media screen and (max-width: 760px) {
    text-align: center;
    width: 70%;
    margin: auto;
  }
`
const Span = styled.span`
  a {
    color: ${options.turquoise};
    font-weight: bold;
    text-decoration: none;
  }
`

export default class PriceSection extends Component {
  constructor() {
    super()
    this.showMoreBtn = this.showMoreBtn.bind(this)

    this.state = {
      showMore: false,
    }
  }

  showMoreBtn = () => {
    this.setState({ showMore: !this.state.showMore })
  }

  render() {
    console.log(this.state.showMore)

    return (
      <Container>
        <GridComponent />
        <Content>
          <H1>NAŠE USLUGE</H1>
          <Flex>
            <Card>
              <Icons>
                <Item>
                  <Bolt />
                  <Pt>Personalni i Miha Bodytec treninzi</Pt>
                </Item>
                <Item>
                  <Weights />
                  <Pt>Samostalno vežbanje</Pt>
                </Item>
                <Item>
                  <Massage />
                  <Pt>Wellness</Pt>
                </Item>
                <Item>
                  <Spa />
                  <Pt>Spa tretmani tela</Pt>
                </Item>
                <Item>
                  <Manicure />
                  <Pt>Manikir</Pt>
                </Item>
                <Item>
                  <Pedicure />
                  <Pt>Pedikir</Pt>
                </Item>
                <Item>
                  <HairDryer />
                  <Pt>Frizer</Pt>
                </Item>
              </Icons>
            </Card>
          </Flex>
          <Text>
            <P>
              Za više informacija pošaljite upit na{" "}
              <Span>
                <a href="mailto:info@fastfit.rs&subject=Za%20Fit%20Plaza">
                  info@fastfit.rs
                </a>
              </Span>{" "}
              ili pozovite brojeve telefona{" "}
              {/* <Span>
                <a href="#phoneInfo">brojeve telefona</a>
                {/* <Link to="#/phoneInfo"></Link> 
              </Span> */}
            </P>
          </Text>
        </Content>
      </Container>
    )
  }
}
