import React from "react"
import Back from "../images/dorcol.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1 } from "../components/theme"
import HeaderSpace from "../components/sections/HeaderSpace"
import TextSection from "../components/sections/TextSection"
import LocationContactSection from "../components/sections/LocationContactSection"
import PlazaUslugeSection from "../components/sections/PlazaUslugeSection"
import Image from "../images/DorcolHeadImage.jpg"
import GallerySection from "../components/sections/GallerySection"
import { dorcolImg, dorcolImgBig } from "../components/Photos"
import PriceNew from "../components/sections/PriceNew"
import PriceSection from "../components/sections/PriceSection"

const Dorcol = () => (
  <Layout>
    <SEO title="Fast Fit Plaza" />
    <HeaderSpace back={Back} />
    <TextSection
      id="dorcol"
      headline="FIT PLAZA"
      par1="Fit Plaza se nalazi u samom srcu Beograda, u novom, savremenom Hotelu Mona Plaza. U okviru
      zajedničkog koncepta Fit Plaza posetiocima nudimo Fast Fit programe treninga, korišćenje
      najsavremenijeg wellnessa i spa, opuštajuću masažu, manikir, pedikir i feniranje. Fast Fit programi
      treninga se na prvom mestu baziraju na inovativnoj"
      span="EMS tehnologiji"
      par2="Vežbačima su na raspolaganju i drugi trening paketi bazirani na
        standardnim fitnes spravama."
      img={Image}
    />
    {/* <PriceSection /> */}
    <PlazaUslugeSection />
    <GallerySection
      photosS={dorcolImg}
      photosL={dorcolImg}
      photosBig={dorcolImgBig}
    />

    <LocationContactSection
      time="Ponedeljak - Nedelja: od 08h do 22h"
      loc="Mona Plaza Hotel"
      addres="Solunska 21, 11000 Beograd"
      href1="tel:+381646153515"
      tel1="Tel: 064 61 53 515"
      href2="tel:+381652202496"
      tel2="Tel: 065 22 02 496"
      mailto="mailto:info@fastfit.rs&subject=Za%20Fit%20Plaza"
      mail="E-mail: info@fastfit.rs"
      map="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11318.973059556003!2d20.4608389!3d44.8267947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x73980118c5ee0184!2sMona%20Plaza%20Hotel!5e0!3m2!1sen!2srs!4v1593259759594!5m2!1sen!2srs"
    />
    <div id="phoneInfo" />
  </Layout>
)

export default Dorcol
